<template>
    <div
        class="timeline-file"
        :class="{ 'has-url': props.url }"
        @click="handleFileClick"
    >
        <div class="timeline-file__icon">
            <iconify-icon icon="fa6-solid:file" />
        </div>
        <div class="timeline-file__info">
            <div class="timeline-file__name">
                {{ props.name }}
            </div>
            <div class="timeline-file__size">
                {{ formatFileSize(props.size) }}
            </div>
        </div>
        <div
            v-if="props.deletable"
            class="timeline-file__actions"
        >
            <el-button
                type="danger"
                plain
                circle
                size="mini"
                @click="handleDeleteClick"
            >
                <iconify-icon icon="fa6-solid:trash-can" />
            </el-button>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: 'UiTimelineFile',
};
</script>

<script setup lang="ts">
import { useFormat } from '@/composable/useFormat';

const { formatFileSize } = useFormat();

interface Props {
    name: string
    size?: number,
    deletable?: boolean,
    url?: string
}

interface Emits {
    (e: 'delete'): void;
}

const props = withDefaults(defineProps<Props>(), {
    size: 0,
    deletable: false,
    url: '',
});

const emit = defineEmits<Emits>();

const handleFileClick = () => {
    if (props.url) {
        window.open(props.url, '_blank');
    }
};

const handleDeleteClick = () => {
    emit('delete');
};
</script>

<style lang="scss">
.timeline-file {
    display: flex;
    align-items: center;
    padding: 8px 12px;

    &.has-url {
        cursor: pointer;
    }

    &__icon {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $--color-text-secondary;
        background-color: $--border-color-base;
        border-radius: 50%;
        margin-right: 12px;
    }

    &__info {
        flex-grow: 1;
    }

    &__name {
        font-size: 13px;
        font-weight: bold;
        color: $--color-text-regular;
    }

    &__size {
        color: $--color-text-secondary;
        font-size: 12px;
    }

    &__actions {
        margin-left: 12px;
    }
}
</style>
