import request from '@/utils/request';

const baseUri = '/requisites';

export const getRequisites = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getRequisite = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });

export const getCards = (params) =>
    request({
        url: `${baseUri}/cards`,
        method: 'GET',
        params
    });

export const generateCard = (id, data) =>
    request({
        url: `${baseUri}/${id}/generate_card`,
        method: 'POST',
        data
    });
