<template>
    <el-dialog
        :visible.sync="currentVisible"
        append-to-body
        lock-scroll
        destroy-on-close
        width="calc(100% - 60px)"
        top="60px"
        custom-class="office-doc-viewer"
    >
        <template #title>
            <div class="office-doc-viewer__title">
                {{ title }}

                <el-button
                    type="text"
                    circle
                    class="office-doc-viewer__download"
                    title="Скачать"
                    @click="handleDownload"
                >
                    <iconify-icon icon="bi:download" />
                </el-button>
            </div>
        </template>
        <div class="office-doc-viewer__wrapper">
            <ui-loading
                v-if="!isIframeLoaded && (isOfficeFile || isRtfFile)"
                class="u-m-auto"
            />
            <iframe
                v-if="isOfficeFile"
                v-show="isIframeLoaded"
                :src="`https://view.officeapps.live.com/op/embed.aspx?src=${url}`"
                class="office-doc-viewer__viewer"
                @load="handleIframeLoad"
            />
            <iframe
                v-else-if="isRtfFile"
                v-show="isIframeLoaded"
                :src="`https://docs.google.com/gview?embedded=true&url=${url}`"
                class="office-doc-viewer__viewer"
                @load="handleIframeLoad"
            />
            <embed
                v-else-if="isPdfFile"
                :src="url"
                type="application/pdf"
                class="office-doc-viewer__viewer"
            >
            <img
                v-else-if="isImageFile"
                :src="url"
                :style="{ transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)` }"
                class="office-doc-viewer__image"
                @wheel="zoomImage"
                @mousedown="startDragging"
                @mousemove="dragImage"
                @mouseup="stopDragging"
                @mouseleave="stopDragging"
            >
            <div
                v-else
                class="u-m-auto"
            >
                Неподдерживаемый формат файла
            </div>
        </div>
    </el-dialog>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiOfficeDocViewer'
});
</script>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';

const emit = defineEmits<{
    (e: 'update:visible', value: boolean): void
}>();

interface Props {
    url: string,
    visible?: boolean,
    title?: string
}

const props = withDefaults(defineProps<Props>(), {
    visible: false,
    title: ''
});

const isIframeLoaded = ref(false);

const isOfficeFile = ref(/\.(docx|xlsx|pptx)$/i.test(props.title));
const isPdfFile = ref(/\.pdf$/i.test(props.title));
const isImageFile = ref(/\.(jpg|jpeg|png|gif)$/i.test(props.title));
const isRtfFile = ref(/\.rtf$/i.test(props.title));

const scale = ref(1);
const translateX = ref(0);
const translateY = ref(0);
let isDragging = false;
let startX = 0;
let startY = 0;

function zoomImage(event: WheelEvent) {
    event.preventDefault();
    const zoomFactor = 0.1;
    scale.value += event.deltaY < 0 ? zoomFactor : -zoomFactor;
    scale.value = Math.max(0.5, Math.min(scale.value, 3)); // Ограничение масштаба от 0.5 до 3
}

function startDragging(event: MouseEvent) {
    isDragging = true;
    startX = event.clientX - translateX.value;
    startY = event.clientY - translateY.value;
}

function dragImage(event: MouseEvent) {
    if (isDragging) {
        translateX.value = event.clientX - startX;
        translateY.value = event.clientY - startY;
    }
}

function stopDragging() {
    isDragging = false;
}

function handleIframeLoad() {
    isIframeLoaded.value = true;
}

const currentVisible = computed({
    get() {
        return props.visible;
    },
    set(val: boolean) {
        emit('update:visible', val);
    }
});

watch(() => currentVisible.value, (val) => {
    if (!val) {
        isIframeLoaded.value = false;
    }
});

watch(() => props.title, (newTitle) => {
    isOfficeFile.value = /\.(docx|xlsx|pptx)$/i.test(newTitle);
    isPdfFile.value = /\.pdf$/i.test(newTitle);
    isImageFile.value = /\.(jpg|jpeg|png|gif)$/i.test(newTitle);
    isRtfFile.value = /\.rtf$/i.test(newTitle);
});

function handleDownload() {
    const url = props.url;
    const filename = props.title;
    const link = document.createElement('a');

    link.href = url + '&download=1';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
</script>

<style lang="scss">
.office-doc-viewer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 90px);
    margin-bottom: 0;
    background: transparent;
    box-shadow: none;

    &__wrapper {
        width: 100%;
        height: 100%;
        background-color: $--color-text-gray;
        display: flex;
        flex-direction: column;
    }

    &__viewer {
        width: 100%;
        height: 100%;
        border: none;
    }

    &__download {
        color: $--color-white;
    }

    &__title {
        color: $--color-white;
        font-weight: bold;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .el-dialog {
        &__header {
            background: #323639;
            padding: 8px;
        }

        &__close {
            color: $--color-white;
        }

        &__body {
            flex-grow: 1;
            padding: 0;
        }

        &__footer {
            display: none;
        }
    }
}
</style>
