/* eslint comma-dangle: ["error", "always-multiline"] */
export default {
    creation: {
        fundingClaim: 'создал{ending} заявку',
        fundingClaimPayment: 'создал{ending} {index} оплату',
        transaction: 'создал{ending} операцию',
        deal: 'создал{ending} сделку',
        staffPersonnelDocument: 'создал{ending} кадровый документ',
        staffAssessmentDocument: 'создал{ending} начисление зарплаты',
        invoice: 'создал{ending} счёт',
        fixedAsset: 'создал{ending} основное средство',
        productInventory: 'создал{ending} товар',
        receiptFixedAssetDocument: 'создал{ending} закупку имущества',
        contract: 'создал{ending} договор',
        internalDocument: 'создал{ending} внутренний договор',
    },
    modification: {
        fundingClaim: 'изменил{ending} заявку',
        'fundingClaim/approval': 'изменил{ending} данные для согласования',
        'fundingClaim/items': 'изменил{ending} расшифровку {index} оплаты',
        'fundingClaim/requisites': 'изменил{ending} реквизиты {index} оплаты',
        fundingClaimPayment: 'изменил{ending} {index} оплату',
        'fundingClaimPayment/requisites': 'изменил{ending} реквизиты {index} оплаты',
        'fundingClaimPayment/items': 'изменил{ending} расшифровку {index} оплаты',
        'fundingClaimPayment/approval': 'изменил{ending} данные для согласования {index} оплаты',
        transaction: 'изменил{ending} операцию',
        'transaction/requisites': 'изменил{ending} реквизиты операции',
        'transaction/items': 'изменил{ending} расшифровку операции',
        deal: 'изменил{ending} сделку',
        'deal/requisites': 'изменил{ending} реквизиты сделки',
        staffPersonnelDocument: 'изменил{ending} кадровый документ',
        staffAssessmentDocument: 'изменил{ending} начисление зарплаты',
        invoice: 'изменил{ending} счёт',
        'invoice/requisites': 'изменил{ending} реквизиты счёта',
        fixedAsset: 'изменил{ending} основное средство',
        productInventory: 'изменил{ending} товар',
        receiptFixedAssetDocument: 'изменил{ending} закупку имущества',
        'receiptFixedAssetDocument/commodityStocks': 'изменил{ending} расшифровку {index} оплаты',
        contract: 'изменил{ending} договор',
        internalDocument: 'изменил{ending} внутренний договор',
    },
    fundingClaimPaymentTransition: {
        forward: 'перевел{ending} {index} оплату в статус',
        backward: 'вернул{ending} {index} оплату в статус',
    },
    fundingClaimPaymentTransactionBinding: 'привязал{ending} <a href="{link}" data-drawer="true">операцию</a> к {index} оплате',
    fundingClaimPaymentTransactionUnbinding: 'отвязал{ending} <a href="{link}" data-drawer="true">операцию</a> от {index} оплаты',
    fundingClaimPayment: {
        paymentDate: 'изменил{ending} Дату {index} оплаты с <span class="u-color-primary">{from}</span> на <span class="u-color-primary">{to}</span>',
    },
    fundingClaim: {
        payments: {
            paymentDate: 'изменил{ending} Дату {index} оплаты с <span class="u-color-primary">{from}</span> на <span class="u-color-primary">{to}</span>',
        },
    },
    transaction: {
        date: 'изменил{ending} Дату операции с <span class="u-color-primary">{from}</span> на <span class="u-color-primary">{to}</span>',
    },

    analyticToDealTransactionBinding: 'привязал{ending} <a href="{link}" data-drawer="true">операцию</a> к {index} сделке',
    analyticToDealTransactionUnbinding: 'отвязал{ending} <a href="{link}" data-drawer="true">операцию</a> от {index} сделки',
    dealTransition: {
        forward: 'перевел{ending} {index} сделку в статус',
        backward: 'вернул{ending} {index} сделку в статус',
    },

    invoiceTransactionBinding: 'привязал{ending} <a href="{link}" data-drawer="true">операцию</a> к {index} счету',
    invoiceTransactionUnbinding: 'отвязал{ending} <a href="{link}" data-drawer="true">операцию</a> от {index} счета',
    invoiceTransition: {
        forward: 'перевел{ending} {index} счёт в статус',
        backward: 'вернул{ending} {index} счёт в статус',
    },

    contract: {
        'versions/add': 'добавил{ending} ',
        'versions/generate': 'сгенерировал{ending} ',
    },
    contractTransition: {
        forwardSystem: 'перевел{ending} {index} договор на этап',
        backwardSystem: 'вернул{ending} {index} договор в статус',
        forward: 'согласовал{ending} {index} этап',
        backward: 'вернул{ending} {index} договор с этапа',
        forwardFirst: 'отправил{ending} {index} договор на согласование',
        backwardFirst: 'вернул{ending} {index} договор на согласование',
        forwardEnd: 'перевел{ending} {index} договор в статус',
    },
    contractApproveByParticipant: {
        contract: 'согласовал{ending}',
        forward: 'согласовал{ending} {index} этап',
    },
};
